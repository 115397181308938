<template>
  <div class="data-view" v-loading="loading">
    <div class="title">
      <img src="/data-view/title-left.png" style="height: 20px; position: relative; top: 8px; margin-right: 10px">
      <span style="position: relative; top: 7px">舆情监测数据大屏</span>
      <img src="/data-view/title-right.png" style="height: 20px; position: relative; top: 8px; margin-left: 10px">
    </div>
    <div class="tool-bar">
      <span style="font-size: 18px; position: relative; right: -135px; top: 10px">{{ date.replace('-', '年').replace('-', '月') }}</span>
      <svg @click="fullScreen" style="cursor: pointer; float: right; margin-left: 20px" t="1663917444990" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1394" width="25" height="25"><path d="M983.04 727.04a40.96 40.96 0 0 0-40.96 40.96v173.592381h-174.08a40.96 40.96 0 1 0 0 82.407619h173.592381A82.407619 82.407619 0 0 0 1024 941.592381v-173.592381a40.96 40.96 0 0 0-40.96-40.96zM941.592381 0h-173.592381a40.96 40.96 0 1 0 0 82.407619h173.592381v173.592381a40.96 40.96 0 1 0 82.407619 0V82.407619A82.407619 82.407619 0 0 0 941.592381 0zM256 941.592381H82.407619v-173.592381a40.96 40.96 0 1 0-82.407619 0v173.592381A82.407619 82.407619 0 0 0 82.407619 1024h173.592381a40.96 40.96 0 1 0 0-82.407619zM40.96 296.96a40.96 40.96 0 0 0 40.96-40.96V82.407619h174.08a40.96 40.96 0 1 0 0-82.407619H82.407619A82.407619 82.407619 0 0 0 0 82.407619v173.592381a40.96 40.96 0 0 0 40.96 40.96z" fill="#84D6E7" p-id="1395"></path><path d="M219.428571 219.428571m82.407619 0l420.32762 0q82.407619 0 82.407619 82.407619l0 420.32762q0 82.407619-82.407619 82.407619l-420.32762 0q-82.407619 0-82.407619-82.407619l0-420.32762q0-82.407619 82.407619-82.407619Z" fill="#84D6E7" p-id="1396"></path></svg>
      <svg v-if="isLocked" @click="isLocked = !isLocked" style="cursor: pointer; float: right" t="1663917518052" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6658" width="25" height="25"><path d="M385.150849 385.662338l-128.895105 0 0-150.377622q0-49.102897 19.436563-91.556444t53.706294-74.677323 80.815185-50.637363 101.786214-18.413586q49.102897 0 94.625375 18.413586t80.815185 50.637363 56.263736 74.677323 20.971029 91.556444l0 150.377622-123.78022 0 0-121.734266q0-64.447552-35.804196-99.74026t-97.182817-35.292707q-55.240759 0-88.999001 35.292707t-33.758242 99.74026l0 121.734266zM826.053946 447.040959q27.62038 0 47.568432 19.948052t19.948052 47.568432l0 317.122877q0 27.62038-9.718282 51.66034t-26.597403 41.942058-39.896104 28.131868-50.637363 10.22977l-516.603397 0q-27.62038 0-50.125874-10.22977t-38.361638-27.108891-24.551449-39.384615-8.695305-48.07992l0-324.283716q0-27.62038 19.436563-47.568432t47.056943-19.948052l61.378621 0 128.895105 0 255.744256 0 123.78022 0 61.378621 0z" p-id="6659" fill="#84D6E7"></path></svg>
      <svg v-if="!isLocked" @click="isLocked = !isLocked" style="cursor: pointer; float: right" t="1663917563036" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7180" width="25" height="25"><path d="M768.25422 0q48.810328 0 94.061569 18.303873t80.333664 50.33565 56.436941 74.740814 21.354518 91.519364l0 150.49851-123.042701 0 0-122.025819q0-64.063555-36.099305-99.654419t-97.112214-35.590864q-54.911619 0-88.468719 35.590864t-33.5571 99.654419l0 124.059583-128.12711 0 0-152.532274q0-48.810328 19.320755-91.519364t53.386296-74.740814 80.333664-50.33565 101.179742-18.303873zM766.220457 693.513406l0 87.451837 0 47.793446q0 27.455809-9.660377 51.860973t-26.438928 41.692155-39.658391 27.455809-50.33565 10.168818l-514.542205 0q-27.455809 0-49.82721-9.660377t-38.641509-26.438928-24.913605-39.14995-8.643496-47.793446l0-323.368421q0-28.472691 19.829196-47.793446t46.268123-19.320755l629.449851 0q28.472691 0 47.793446 19.320755t19.320755 47.793446l0 179.988083z" p-id="7181" fill="#84D6E7"></path></svg>
      <span id="project-select"
            style="width: 180px; padding: 5px 10px; border-radius: 5px; background-color: #437D89; float: right; margin-right: 15px; cursor: pointer; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">项目：{{
          name
        }}</span>
      <div id="project-array" style="position: absolute; right: 86px; margin-top: 15px; background-color: #134551; width: 180px; padding: 5px 10px; border-radius: 5px; z-index: 99; color: #40ECFF; text-align: center; display: none">
        <ul>
          <li @click="changeProject(item, index)" class="project-li" v-for="(item, index) in projectArray" :key="item.cid">{{ item.name }}</li>
        </ul>
      </div>
    </div>
    <div class="left-box">
      <div class="part3 border">
        <div class="item-title">
          <img src="/data-view/item-title-left.png" style="height: 15px; margin-right: 10px">
          <span>舆情分布</span>
          <img src="/data-view/item-title-right.png" style="height: 15px; margin-left: 10px">
        </div>
        <div class="item-content">
          <div id="emotion-pie" style="height: 100%"></div>
        </div>
      </div>
      <div class="part3 border">
        <div class="item-title">
          <img src="/data-view/item-title-left.png" style="height: 15px; margin-right: 10px">
          <span>热词云图</span>
          <img src="/data-view/item-title-right.png" style="height: 15px; margin-left: 10px">
        </div>
        <div class="item-content">
          <div id="word-cloud" style="height: 100%"></div>
        </div>
      </div>
      <div class="part3 border">
        <div class="item-title">
          <img src="/data-view/item-title-left.png" style="height: 15px; margin-right: 10px">
          <span>站点排行</span>
          <img src="/data-view/item-title-right.png" style="height: 15px; margin-left: 10px">
        </div>
        <div class="item-content">
          <div id="site-bar" style="height: 100%"></div>
        </div>
      </div>
    </div>
    <div class="center-box">
      <div class="part2 border">
        <div class="item-title">
          <img src="/data-view/item-title-left.png" style="height: 15px; margin-right: 10px">
          <span>最新舆情</span>
          <img src="/data-view/item-title-right.png" style="height: 15px; margin-left: 10px">
        </div>
        <div class="item-content hide-scroll" id="latest-news">
          <div v-for="item in latestNews" :key="item" class="item-data">
            <div class="data-title">
              <span v-if="item.isDanger === 0" class="success-tag">正面</span>
              <span v-else-if="item.isDanger === 1" class="error-tag">负面</span>
              <span v-else class="warning-tag">敏感</span>
              {{ item.title }}
            </div>
            <div class="data-info">
              <span style="float: left">来源：{{ item.siteName }}</span>
              <span style="float: right">时间：{{ item.publishDate.substring(0, 19) }}</span>
            </div>
          </div>

        </div>
      </div>
      <div class="part2 border">
        <div class="item-title">
          <img src="/data-view/item-title-left.png" style="height: 15px; margin-right: 10px">
          <span>负面舆情</span>
          <img src="/data-view/item-title-right.png" style="height: 15px; margin-left: 10px">
        </div>
        <div class="item-content hide-scroll" id="latest-danger-news">
          <div v-for="item in latestDangerNews" :key="item" class="item-data">
            <div class="data-title">
              <span class="error-tag">负面</span>
              {{ item.title }}
            </div>
            <div class="data-info">
              <span style="float: left">来源：{{ item.siteName }}</span>
              <span style="float: right">时间：{{ item.publishDate.substring(0, 19) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right-box">
      <div class="part3 border">
        <div class="item-title">
          <img src="/data-view/item-title-left.png" style="height: 15px; margin-right: 10px">
          <span>情感走势</span>
          <img src="/data-view/item-title-right.png" style="height: 15px; margin-left: 10px">
        </div>
        <div class="item-content">
          <div id="emotion-line" style="height: 100%"></div>
        </div>
      </div>
      <div class="part3 border">
        <div class="item-title">
          <img src="/data-view/item-title-left.png" style="height: 15px; margin-right: 10px">
          <span>媒体类型</span>
          <img src="/data-view/item-title-right.png" style="height: 15px; margin-left: 10px">
        </div>
        <div class="item-content">
          <div id="source-bar" style="height: 100%"></div>
        </div>
      </div>
      <div class="part3 border">
        <div class="item-title">
          <img src="/data-view/item-title-left.png" style="height: 15px; margin-right: 10px">
          <span>地域分布</span>
          <img src="/data-view/item-title-right.png" style="height: 15px; margin-left: 10px">
        </div>
        <div class="item-content">
          <div id="location-map" style="height: 100%"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapJSON } from '@/api/sortStatistics'
import { fetchAllProject, fetchResult } from '@/api/dataView'
export default {
  data () {
    return ({
      loading: true,
      isLocked: false,
      emotionPie: '',
      wordCloud: '',
      siteBar: '',
      emotionLine: '',
      sourceBar: '',
      locationMap: '',
      projectArray: [],
      index: 0,
      cId: '',
      name: 'loading',
      latestNews: [],
      latestDangerNews: [],
      date: ''
    })
  },
  mounted () {
    document.getElementById('project-select').onclick = function () {
      document.getElementById('project-array').style.display = 'block'
    }
    document.getElementById('project-array').addEventListener('mouseleave', function () {
      document.getElementById('project-array').style.display = 'none'
    })
    this.getAllProject()
    let _this = this
    setInterval(function () {
      if (!_this.isLocked) {
        if (_this.index === _this.projectArray.length - 1) {
          _this.index = 0
        } else {
          _this.index += 1
        }
        _this.cId = _this.projectArray[_this.index].cid
        _this.name = _this.projectArray[_this.index].name
        _this.loadDataView()
      } else {
        _this.loadDataView()
      }
    }, 1000 * 60)
    window.onresize = () => {
      this.emotionPie.resize()
      this.wordCloud.resize()
      this.siteBar.resize()
      this.emotionLine.resize()
      this.sourceBar.resize()
      this.locationMap.resize()
    }
  },
  methods: {
    async getAllProject () {
      const res = await fetchAllProject()
      if (res.data && res.data.length > 0) {
        this.projectArray = res.data
        this.cId = res.data[0].cid
        this.name = res.data[0].name
        this.loadDataView()
        this.autoScroll('latest-news', 20)
        this.autoScroll('latest-danger-news', 20)
      }
    },
    async loadDataView () {
      const res = await fetchResult({ cid: this.cId })
      this.loading = false
      this.date = res.data.date + '日'
      this.latestNews = res.data.latestNews
      this.latestDangerNews = res.data.latestDangerNews
      this.loadEmotionPie(res.data.emotionPie)
      this.loadWordCloud(res.data.wordCloud)
      this.loadSiteBar(res.data.siteBar)
      this.loadEmotionLine(res.data.emotionLine)
      this.loadSourceBar(res.data.sourceBar)
      this.loadLocationMap(res.data.locationMap)
    },
    async changeProject (item, index) {
      this.loading = true
      this.index = index
      this.cId = item.cid
      this.name = item.name
      this.loadDataView()
    },
    fullScreen () {
      document.documentElement.requestFullscreen()
    },
    autoScroll (dom, interval) {
      var parentDom = document.getElementById(dom)
      setInterval(() => {
        if (parentDom.scrollHeight - parentDom.clientHeight - parentDom.scrollTop < 1) {
          parentDom.scrollTop = 0
        } else {
          parentDom.scrollTop++
        }
      }, interval)
    },
    loadEmotionPie (data) {
      this.emotionPie = this.$echarts.init(document.getElementById('emotion-pie'))
      var option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          left: 'left',
          bottom: '0%',
          textStyle: {
            color: '#4BF9FF'
          }
        },
        color: ['#5C7BD7', '#ED6666', '#FAC758'],
        series: [
          {
            name: '舆情分布',
            type: 'pie',
            radius: ['40%', '75%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 5,
              borderColor: '#103944',
              borderWidth: 2
            },
            label: {
              color: '#4BF9FF'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            data: data
          }
        ]
      }
      this.emotionPie.setOption(option)
    },
    loadWordCloud (data) {
      this.wordCloud = this.$echarts.init(document.getElementById('word-cloud'))
      var option = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c}'
        },
        series: [{
          name: '热词',
          type: 'wordCloud',
          size: ['100%', '100%'],
          textRotation: [0, 45, 90, -45],
          textPadding: 0,
          autoSize: {
            enable: true,
            minSize: 14
          },
          textStyle: {
            fontFamily: 'sans-serif',
            // Color can be a callback function or a color string
            color: function () {
              // Random color
              return '#77EAFF'
            }
          },
          emphasis: {
            focus: 'self',
            textStyle: {
              textShadowBlur: 10,
              textShadowColor: '#333'
            }
          },
          data: data
        }]
      }
      this.wordCloud.setOption(option)
    },
    loadSiteBar (data) {
      var xAxis = []
      var series = []
      for (var i = 0; i < data.length; i++) {
        xAxis.push(data[i].siteName)
        series.push(data[i].total)
      }
      this.siteBar = this.$echarts.init(document.getElementById('site-bar'))
      var option = {
        xAxis: {
          type: 'category',
          data: xAxis,
          axisLabel: {
            interval: 0,
            rotate: 45,
            textStyle: {
              color: '#4BF9FF'
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            textStyle: {
              color: '#4BF9FF'
            }
          },
          splitLine: {
            lineStyle: {
              color: ['#164E5C']
            }
          }
        },
        grid: {
          top: '5%',
          bottom: '25%'
        },
        color: ['#5C7BD8'],
        series: [
          {
            itemStyle: {
              borderRadius: [5, 5, 0, 0]
            },
            data: series,
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            }
          }
        ]
      }
      this.siteBar.setOption(option)
    },
    loadEmotionLine (data) {
      for (var i = 0; i < data.xAxis.length; i++) {
        data.xAxis[i] = data.xAxis[i] + '时'
      }
      this.emotionLine = this.$echarts.init(document.getElementById('emotion-line'))
      var option = {
        legend: {
          left: 'top',
          top: '3%',
          textStyle: {
            color: '#4BF9FF'
          },
          icon: 'roundRect'
        },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          data: data.xAxis,
          axisLabel: {
            textStyle: {
              color: '#4BF9FF'
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            textStyle: {
              color: '#4BF9FF'
            }
          },
          splitLine: {
            lineStyle: {
              color: ['#164E5C']
            }
          }
        },
        grid: {
          top: '20%',
          bottom: '12%',
          right: '5%'
        },
        color: ['#5C7BD7', '#ED6666', '#FAC758'],
        series: [
          {
            data: data.emotion0,
            type: 'line',
            smooth: true,
            name: '正面'
          },
          {
            data: data.emotion1,
            type: 'line',
            smooth: true,
            name: '负面'
          },
          {
            data: data.emotion2,
            type: 'line',
            smooth: true,
            name: '敏感'
          }
        ]
      }
      this.emotionLine.setOption(option)
    },
    loadSourceBar (data) {
      for (var i = 0; i < data.xAxis.length; i++) {
        data.xAxis[i] = data.xAxis[i] + '时'
      }
      this.sourceBar = this.$echarts.init(document.getElementById('source-bar'))
      var option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {
          left: 'top',
          top: '3%',
          textStyle: {
            color: '#4BF9FF'
          },
          icon: 'roundRect'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          axisLabel: {
            textStyle: {
              color: '#4BF9FF'
            }
          },
          splitLine: {
            lineStyle: {
              color: ['#164E5C']
            }
          }
        },
        yAxis: {
          type: 'category',
          data: data.xAxis,
          axisLabel: {
            textStyle: {
              color: '#4BF9FF'
            }
          }
        },
        color: ['#3372C7', '#C5191B', '#92CC7B', '#6B759A', '#FC8456', '#0898C2', '#FAC861', '#BCBCBC', '#BDE0F5', '#1F1E1C', '#F5A5B4'],
        series: [
          {
            'stack': '总量',
            'data': data.series.网媒,
            'name': '网媒',
            'type': 'bar'
          },
          {
            'stack': '总量',
            'data': data.series.贴吧,
            'name': '贴吧',
            'type': 'bar'
          },
          {
            'stack': '总量',
            'data': data.series.微信,
            'name': '微信',
            'type': 'bar'
          },
          {
            'stack': '总量',
            'data': data.series.博客,
            'name': '博客',
            'type': 'bar'
          },
          {
            'stack': '总量',
            'data': data.series.论坛,
            'name': '论坛',
            'type': 'bar'
          },
          {
            'stack': '总量',
            'data': data.series.电子报,
            'name': '电子报',
            'type': 'bar'
          },
          {
            'stack': '总量',
            'data': data.series.微博,
            'name': '微博',
            'type': 'bar'
          },
          {
            'stack': '总量',
            'data': data.series.其它,
            'name': '其它',
            'type': 'bar'
          },
          {
            'stack': '总量',
            'data': data.series.问答,
            'name': '问答',
            'type': 'bar'
          },
          {
            'stack': '总量',
            'data': data.series.客户端,
            'name': '客户端',
            'type': 'bar'
          },
          {
            'stack': '总量',
            'data': data.series.视频,
            'name': '视频',
            'type': 'bar'
          }
        ]
      }
      this.sourceBar.setOption(option)
    },
    loadLocationMap (data) {
      this.locationMap = this.$echarts.init(document.getElementById('location-map'))
      var json = mapJSON()
      this.$echarts.registerMap('sc', json, {})
      data.sort(this.compare('value'))
      var option = this.refreshMap(data)
      this.locationMap.setOption(option)
    },
    refreshMap (data) {
      return {
        tooltip: { trigger: 'item',
          formatter: function (data) {
            // console.log(data);
            if (!isNaN(data.value)) {
              return data.name + '：' + data.value + '条'
            } else {
              return data.name + '：' + 0 + '条'
            }
          }
        },
        visualMap: {
          min: data[data.length - 1].value === data[0].value ? 1 : data[data.length - 1].value,
          max: data[0].value,
          text: ['高', '低'],
          realtime: false,
          calculable: true,
          bottom: '30%',
          right: '10%',
          inRange: { color: ['#E3E2E2', '#A5AEC9', '#929FC6', '#8192C5', '#6E84C5', '#6079C6', '#5470C6'] },
          show: false
        },
        series: [{
          name: '',
          type: 'map',
          mapType: 'sc',
          aspectScale: 0.85,
          layoutCenter: ['50%', '70%'],
          layoutSize: '140%',
          itemStyle: {
            normal: {
              borderWidth: 1,
              borderColor: '#FFF',
              label: {
                show: false
              }
            },
            emphasis: {
              borderWidth: 2,
              borderColor: '#FFF',
              areaColor: '#92CC76'
            }
          },
          label: {
            // normal: {show: true, textStyle: {color: '#000'}},
            emphasis: { show: true, textStyle: { color: '#333' } }
          },
          data: data
        }]
      }
    },
    compare (p) { // 这是比较函数
      return function (m, n) {
        var a = m[p]
        var b = n[p]
        return b - a // 升序
      }
    }
  }
}
</script>
<style>
.hide-scroll::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.hide-scroll {
  scrollbar-width: none;
}
</style>
<style lang="scss">
.data-view {
  overflow: hidden;
  background: linear-gradient(to right, #00232A, #0D3642, #00232A);
  padding: 0 20px 20px 20px;
  height: 100vh;
  .el-loading-mask {
    background-color: rgba(0,0,0,.5);
  }
  .title {
    height: 42px;
    background: url("/data-view/title-line.png") no-repeat;
    background-size: 100% 100%;
    text-align: center;
    color: #FFFFFF;
    font-size: 25px;
  }
  .tool-bar {
    height: 40px;
    //background-color: #FFFFFF;
    position: absolute;
    top: 42px;
    width: calc(100% - 40px);
    color: #FFFFFF;
    text-align: center;
    .project-li {
      width: 160px;
      margin: 5px 0px 5px -40px;
      border-radius: 3px;
      padding: 5px 0;
      cursor: pointer;
    }
    .project-li:hover {
      background-color: #FFFFFF;
      color: #0D3642;
    }
  }
  .left-box {
    padding-top: 20px;
    overflow: auto;
    width: 28%;
    float: left;
  }
  .center-box {
    padding-top: 20px;
    overflow: auto;
    width: calc(100% - 56% - 40px);
    margin: 0 20px;
    float: left;
  }
  .right-box {
    padding-top: 20px;
    overflow: auto;
    width: 28%;
    float: right;
  }
  .part2 {
    margin: 20px 0;
    height: calc((100vh - 120px) / 2);
    background-color: #103944;
    .item-title {
      text-align: center;
      height: 15px;
      color: #FFFFFF;
    }
    .item-content {
      height: calc(100% - 15px);
      overflow-y: auto;
      padding: 20px;
      .item-data {
        background-color: #134551;
        padding: 10px 20px;
        margin-bottom: 10px;
        .data-title {
          word-break:break-all;
          color: #40ECFF;
          font-size: 14px;
          line-height: 22px;
          .success-tag {background: linear-gradient(to bottom, #83C3FF, #58ABFF); color: #FFFFFF; font-size: 10px; padding: 3px 5px; border-radius: 5px 0 5px 0; margin-right: 5px}
          .error-tag {background: linear-gradient(to bottom, #FE8484, #FF5656); color: #FFFFFF; font-size: 10px; padding: 3px 5px; border-radius: 5px 0 5px 0; margin-right: 5px}
          .warning-tag {background: linear-gradient(to bottom, #FDE38D, #FAC758); color: #FFFFFF; font-size: 10px; padding: 3px 5px; border-radius: 5px 0 5px 0; margin-right: 5px}
        }
        .data-info {
          margin-top: 10px;
          font-size: 13px;
          overflow: auto;
          color: #94EBFE;
        }
      }
    }
  }
  .part3 {
    margin: 20px 0;
    height: calc((100vh - 140px) / 3);
    background-color: #103944;
    .item-title {
      text-align: center;
      height: 15px;
      color: #FFFFFF;
    }
    .item-content {
      height: calc(100% - 15px);
      padding: 0 20px;
    }
  }
  .border {
    border: 1px solid #07f1ff;
    border-radius: 5px;
  }
}
</style>
