import request from '@/utils/request'
export function fetchAllProject () {
  return request({
    url: '/dataView/fetchAllProject',
    method: 'POST'
  })
}
export function fetchResult (data) {
  return request({
    url: '/dataView/fetchResult',
    method: 'POST',
    data
  })
}
